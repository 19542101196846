// src/components/BookList.js
import { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { getBookList } from "../api";
import { BookListItem } from "../api/types";

const PAGE_SIZE = 40;

const BookList = () => {
  const [books, setBooks] = useState<BookListItem[]>([]);
  const [nextCursor, setNextCursor] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const hasFetched = useRef(false);
  const [hasMoreBooks, setHasMoreBooks] = useState(true);

  const fetchBooks = useCallback(async () => {
    if (loading || !hasMoreBooks) return; // Prevent fetching if already loading or no more books
    setLoading(true);
    try {
      const { books: newBooks, next_cursor } = await getBookList(
        nextCursor,
        PAGE_SIZE
      );
      setBooks((prevBooks) => [...prevBooks, ...newBooks]);
      if (newBooks.length > 0) {
        setNextCursor(next_cursor || null);
      } else {
        setHasMoreBooks(false); // No more books to fetch
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [nextCursor, loading, hasMoreBooks]);

  useEffect(() => {
    if (hasFetched.current) return;
    hasFetched.current = true;
    fetchBooks();
  }, [fetchBooks]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      const offsetHeight = document.documentElement.offsetHeight;

      // Check if user has scrolled to the bottom
      if (windowHeight + scrollTop >= offsetHeight - 1 && !loading) {
        fetchBooks();
      }

      // Check if user has scrolled up significantly
      if (scrollTop < 100 && !hasMoreBooks) {
        setHasMoreBooks(true); // Reset to allow fetching again
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [fetchBooks, loading, hasMoreBooks]);

  return (
    <div>
      <h2>Books</h2>
      {books.length === 0 && !loading && <p>No books available.</p>}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
          gap: "16px",
          paddingBottom: "32px",
        }}
      >
        {books.map((book, index) => (
          <Link
            to={`/books/${book.id}`}
            key={index}
            style={{ textDecoration: "none", color: "inherit" }}
            title={`${book.title} by ${book.authors}`}
          >
            <div
              style={{
                border: "1px solid #ccc",
                padding: "8px",
                textAlign: "center",
                height: "auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <img
                src={book.image_l}
                alt={book.title}
                style={{
                  width: "100%",
                  height: "auto",
                  maxHeight: "200px",
                  objectFit: "contain",
                }}
              />
              <div
                style={{
                  marginTop: "auto",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  height: "80px",
                }}
              >
                <h3
                  style={{
                    margin: "8px 0",
                    fontSize: "0.9em",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {book.title}
                </h3>
                <p
                  style={{
                    margin: "0 0 8px",
                    fontSize: "0.8em",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  by {book.authors}
                </p>
              </div>
            </div>
          </Link>
        ))}
      </div>
      {loading && <p>Loading books...</p>}
    </div>
  );
};

export default BookList;
